<template>
	<form class="panel-form" @submit.prevent="onFormSubmit">
		<div>
			<event-type-selector v-model="eventType" :disabled="isDisabled" />
			<div v-if="isProviderType" class="form-group">
				<label for="provider_id" class="form-label">
					Provider
				</label>
				<multiselect
					id="provider_id"
					ref="multiselect"
					v-model="primaryProvider"
					required
					:disabled="isDisabled"
					track-by="id"
					label="label"
					placeholder="Select a provider"
					:options="allProviders"
					:multiple="false"
					:select-label="''"
					:selected-label="''"
				>
					<template slot="noResult">
						No providers found
					</template>
				</multiselect>
				<span v-if="form.errors.has('providers')" class="form-error">
					{{ form.errors.first('providers') }}
				</span>
			</div>
			<div v-if="showSecondaryProvider" class="form-group">
				<label for="secondary_provider_id" class="form-label">
					Secondary Contact (Optional)
				</label>

				<multiselect
					id="secondary_provider_id"
					ref="multiselect"
					v-model="secondaryProvider"
					required
					:disabled="isDisabled"
					track-by="id"
					label="label"
					placeholder="Select a provider"
					:options="internalProviders"
					:multiple="false"
					:select-label="''"
					:selected-label="''"
				>
					<template slot="noResult">
						No providers found
					</template>
				</multiselect>
			</div>

			<div v-if="isLinkedCalendarType" class="form-group">
				<label for="linked_partner_id" class="form-label">
					Affiliate Practice
				</label>
				<div class="relative">
					<select
						id="linked_partner_id"
						v-model="linkedPartner"
						required
						:disabled="isDisabled"
						name="linked_partner_id"
						placeholder="Select an affiliate practice"
						class="form-input"
						:class="[
							linkedPartner ? 'text-gray-700' : 'text-gray-500',
						]"
					>
						<option :value="null" disabled
							>Select an Affiliate Practice</option
						>
						<option
							v-for="partner in linkedPartners"
							:key="partner.id"
							:value="partner.id"
						>
							{{ partner.name }}
						</option>
					</select>
					<div
						class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
					>
						<font-awesome-icon
							class="fill-current h-4 w-4 mt-4 mr-2"
							:icon="['far', 'chevron-down']"
						></font-awesome-icon>
					</div>
				</div>
			</div>
			<div v-if="linkedPartner" class="form-group">
				<label for="linked_partner_calendar_id" class="form-label">
					Calendar (* Default Calendar)
				</label>
				<div class="relative mb-2">
					<select
						id="linked_partner_calendar_id"
						v-model="linkedCalendarId"
						required
						name="linked_partner_calendar_id"
						placeholder="Select a Calendar"
						class="form-input"
						:class="[
							linkedCalendarId
								? 'text-gray-700'
								: 'text-gray-500',
						]"
					>
						<option :value="null" disabled>
							Select a Calendar
						</option>
						<option
							v-for="linkedCalendar in linkedPartnerCalendars"
							:key="`calendar-${linkedCalendar.id}`"
							:value="linkedCalendar.id"
							>{{ linkedCalendar.name }}</option
						>
					</select>
					<div
						class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
					>
						<font-awesome-icon
							class="fill-current h-4 w-4 mt-4 mr-2"
							:icon="['far', 'chevron-down']"
						></font-awesome-icon>
					</div>
				</div>
			</div>
			<div v-if="isProviderGroupType" class="form-group">
				<label for="provider_group_id" class="form-label">
					Provider Group
				</label>
				<div class="relative">
					<select
						id="provider_group_id"
						v-model="providerGroupId"
						required
						:disabled="isDisabled"
						name="provider_group_id"
						class="form-input"
						:class="[
							providerGroupId ? 'text-gray-700' : 'text-gray-500',
						]"
					>
						<option :value="null" disabled>
							Select a provider group
						</option>
						<option
							v-for="groups in providerGroups"
							:key="groups.id"
							:value="groups.id"
							>{{ groups.name }}</option
						>
					</select>
					<div
						class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
					>
						<font-awesome-icon
							class="fill-current h-4 w-4 mt-4 mr-2"
							:icon="['far', 'chevron-down']"
						></font-awesome-icon>
					</div>
				</div>
			</div>
			<group-coverage-table
				v-if="isProviderGroupType && providerGroupId"
				:group-id="providerGroupId"
				:event="event"
				:provider-group-providers="providerGroupProviders"
				@coverage:set="onGroupChange"
			/>
			<div v-if="hasTypeSelected" class="form-group">
				<label for="start" class="form-label">
					Start Date
					<span v-if="inDifferentTimezone && partnerStartTime">
						({{ partnerStartTime }}
						{{ timezones[partner.timezone] || partner.timezone }})
					</span>
				</label>
				<date-picker
					id="start"
					v-model="form.starts_at"
					name="start"
					:min-date="today"
					mode="dateTime"
					color="orange"
					placeholder="End Date"
					is-required
				>
					<template v-slot="{ inputValue, inputEvents }">
						<input
							class="form-input"
							:value="inputValue"
							:disabled="isDisabled"
							placeholder="Start Date"
							v-on="inputEvents"
						/>
					</template>
				</date-picker>
				<span v-if="form.errors.has('starts_at')" class="form-error">
					{{ form.errors.first('starts_at') }}
				</span>
			</div>
			<div v-if="hasTypeSelected" class="form-group">
				<label for="end" class="form-label">
					End Date
					<span v-if="inDifferentTimezone && partnerEndTime">
						({{ partnerEndTime }}
						{{ timezones[partner.timezone] || partner.timezone }})
					</span>
				</label>
				<date-picker
					id="end"
					v-model="form.ends_at"
					name="end"
					:min-date="form.starts_at"
					mode="dateTime"
					color="orange"
					is-required
				>
					<template v-slot="{ inputValue, inputEvents }">
						<input
							:disabled="isDisabled"
							class="form-input"
							:value="inputValue"
							placeholder="End Date"
							v-on="inputEvents"
						/>
					</template>
				</date-picker>
				<span v-if="form.errors.has('duration')" class="form-error">
					{{ form.errors.first('duration') }}
				</span>
			</div>
			<recurring-event-actions
				v-if="displayRecurringActions"
				:disabled="isDisabled"
				:form="form"
				:is-recurring="isRecurring"
				@byweekday:change="onByWeekdayChange"
				@frequency:change="onFrequencyChange"
				@until:change="onUntilChange"
				@recurrence:change="onWantRecurrenceChange"
			/>
			<div
				v-if="hasShareableCalendars"
				data-cy="shareable-calendars-section"
				class="form-group"
			>
				<label class="form-label">
					Share to Calendar(s):
				</label>
				<div class="flex flex-wrap">
					<div
						v-for="shareableCalendar in shareableCalendars"
						:key="shareableCalendar.id"
						class="flex-auto w-2/4"
					>
						<div class="form-check">
							<input
								:id="
									`shareable-calendar-${shareableCalendar.id}`
								"
								v-model="calendars"
								:disabled="isDisabled"
								type="checkbox"
								:value="shareableCalendar.id"
								class="form-check-input"
								name="patient-available-checkbox"
							/>
							<label
								:for="
									`shareable-calendar-${shareableCalendar.id}`
								"
								data-cy="shareable-calendar"
								class="form-check-label"
							>
								{{ shareableCalendar.name }}
							</label>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!wantRecurrence && isParentEvent" class="form-group">
				<span class="form-error">
					Updating this event will remove all associated recurring
					events from the calendar.
				</span>
			</div>
			<div v-if="!confirm" class="flex justify-between">
				<button
					:disabled="!hasOfficeManagerAccess"
					type="button"
					class="btn btn-danger w-50 mr-2"
					@click.prevent="confirm = 'delete'"
				>
					<div class="btn-ripple"></div>
					<font-awesome-icon
						class="btn-icon"
						:icon="[
							'far',
							isEditableFromCalendar ? 'trash' : 'unlink',
						]"
					/>
					<span class="btn-label">
						{{ isEditableFromCalendar ? 'Delete' : 'Unlink' }}
					</span>
				</button>
				<button
					v-if="isShared"
					ref="last"
					type="submit"
					:disabled="isDisabled"
					class="btn btn-outline-success w-50 ml-2"
					@keydown.tab="onLastElementTab"
					@click.prevent="confirm = 'unlink'"
				>
					<div class="btn-ripple"></div>
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'save']"
						aria-hidden="true"
					/>
					<span class="btn-label">Save</span>
				</button>
				<button
					v-else
					ref="last"
					type="submit"
					:disabled="isDisabled"
					class="btn btn-outline-success w-50 ml-2"
					@keydown.tab="onLastElementTab"
				>
					<div class="btn-ripple"></div>
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'save']"
						aria-hidden="true"
					/>
					<span class="btn-label">Save</span>
				</button>
			</div>
			<delete-event-actions
				v-else-if="confirm === 'delete'"
				:is-editable-from-calendar="isEditableFromCalendar"
				:is-recurring="event.is_recurring"
				:is-parent-event="isParentEvent"
				@delete="onDeleteClick"
				@last-element-tab="onLastElementTab"
				@cancel="onCancel"
			/>
			<div v-else-if="confirm === 'unlink'" class="flex flex-col">
				<div class="flex justify-center mb-6 font-nunito">
					This event is attached to multiple calendars. Do you want to
					update this event for all calendars?
				</div>
				<div class="flex justify-between">
					<button
						ref="last"
						type="button"
						class="btn btn-dark w-50 mr-2"
						@click.prevent="onCancel"
					>
						<div class="btn-ripple"></div>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
							aria-hidden="true"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple"></div>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'trash']"
						/>
						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import moment from 'moment'
import Event from 'App/Models/Event'
import Multiselect from 'vue-multiselect'
import timezones from '@/config/timezones'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty, groupBy, sortBy } from 'lodash'
import EditEventForm from 'App/Forms/EditEventForm'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import EventTypeSelector from './EventTypeSelector.vue'
import DeleteEventActions from './DeleteEventActions.vue'
import RecurringEventActions from './RecurringEventActions.vue'
import GroupCoverageTable from '@/components/GroupCoverageTable'

/**
 * Const representing the timestamp format the API uses.
 *
 * @type {String}
 */
const API_FORMAT = 'YYYY-MM-DD HH:mm:ss'

/**
 * Const representing the datetime-local format.
 *
 * @type {String}
 */
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		DeleteEventActions,
		EventTypeSelector,
		GroupCoverageTable,
		Multiselect,
		RecurringEventActions,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all internal/external providers.
		 *
		 * @return {Array}
		 */
		allProviders() {
			let providers = this.getAllProviders || []

			providers = sortBy(providers, ['display_name'])

			providers = providers.map(provider => {
				const label = this.getProviderFullName(provider)

				return {
					label,
					id: provider.id,
					type: provider.type,
					display_name: provider.display_name,
				}
			})

			const { external, internal } = groupBy(providers, 'type')

			return [...(internal || []), ...(external || [])]
		},

		/**
		 * Get the current route's calendar.
		 *
		 * @return {Object}
		 */
		calendar() {
			return this.findCalendar(this.$route.params.id)
		},

		/**
		 * Determine if the recurring event actions must be shown.
		 *
		 * @return {Boolean}
		 */
		displayRecurringActions() {
			return (
				this.isLessThan24Hours &&
				(this.isParentEvent || !this.isRecurring)
			)
		},

		/**
		 * Get the current event being edited.
		 *
		 * @return {Object}
		 */
		event() {
			return this.id ? new Event(this.findEvent(this.id)) : null
		},

		/**
		 * Get the selected event type name.
		 *
		 * @return {String}
		 */
		getEventTypeName() {
			return this.hasTypeSelected ? this.eventType.name : ''
		},

		/**
		 * Check that shareable calendars exist.
		 *
		 * @return {Boolean}
		 */
		hasShareableCalendars() {
			return this.shareableCalendars.length > 0 && this.hasTypeSelected
		},

		/**
		 * Determine if the an event type has been selected.
		 *
		 * @return {Boolean}
		 */
		hasTypeSelected() {
			return !!this.eventType?.name
		},

		/**
		 * Determine if the user is in a different timezone than the partner's timezone.
		 *
		 * @return {Boolean}
		 */
		inDifferentTimezone() {
			const now = moment()
			const partner = moment().tz(this.partner.timezone)

			return now.utcOffset() !== partner.utcOffset()
		},

		/**
		 * Determine if the event is child occurrence.
		 *
		 * @return {Boolean}
		 */
		isChildOccurrence() {
			return !!(
				!this.isParentEvent &&
				this.activeDate &&
				this.event.is_recurring
			)
		},

		/**
		 * Determine if the event can be edited from the current calendar.
		 *
		 * @return {Boolean}
		 */
		isEditableFromCalendar() {
			return this.isPrimaryCalendarEvent(this.id)
		},

		/**
		 * Determine if the form's fields should be disabled.
		 *
		 * @return {Boolean}
		 */
		isDisabled() {
			return !this.hasOfficeManagerAccess || !this.isEditableFromCalendar
		},

		/**
		 * Determine if the selected provider is internal.
		 *
		 * @return {Boolean}
		 */
		isInternalProvider() {
			if (!this.primaryProvider) {
				return false
			}

			const provider = this.allProviders.find(
				provider => provider.id === this.primaryProvider.id
			)

			return provider?.type === 'internal'
		},

		/**
		 * Determine is the type selected is linked calendar.
		 *
		 * @return {Boolean}
		 */
		isLinkedCalendarType() {
			return this.getEventTypeName === 'linked-calendar'
		},

		/**
		 * Determine if the event is a parent event.
		 *
		 * @return {Boolean}
		 */
		isParentEvent() {
			if (!this.event || !this.activeDate || !this.event?.starts_at) {
				return false
			}

			const start = this.event.starts_at

			return !!(
				moment.utc(this.activeDate).isSame(start.utc(), 'day') &&
				this.event.is_recurring
			)
		},

		/**
		 * Determine is the type selected is provider.
		 *
		 * @return {Boolean}
		 */
		isProviderType() {
			return this.getEventTypeName === 'provider'
		},

		/**
		 * Determine is the type selected is provider group.
		 *
		 * @return {Boolean}
		 */
		isProviderGroupType() {
			return this.getEventTypeName === 'provider-group'
		},

		/**
		 * Determine if the event has recurrence.
		 *
		 * @return {Boolean}
		 */
		isRecurring() {
			if (!this.event) {
				return false
			}

			return !!this.event?.is_recurring
		},

		/**
		 * Get the partner's internal providers sorted by last name.
		 *
		 * @return {Array}
		 */
		internalProviders() {
			if (!this.calendar) {
				return []
			}

			return this.allProviders.filter(provider => {
				return provider.type === 'internal'
			})
		},

		/**
		 * Determine if the event is a shared event.
		 *
		 * @return {Boolean}
		 */
		isShared() {
			return this.isSharedEvent(this.id)
		},

		/**
		 * Get the form's end time in the partner's timezone.
		 *
		 * @return {String}
		 */
		partnerEndTime() {
			if (!this.form.ends_at) {
				return ''
			}

			return moment(this.form.ends_at, DATE_TIME_FORMAT)
				.tz(this.partner.timezone)
				.format('hh:mm A')
		},

		/**
		 * Get the form's start time in the partner's timezone.
		 *
		 * @return {String}
		 */
		partnerStartTime() {
			if (!this.form.starts_at) {
				return ''
			}

			return moment(this.form.starts_at, DATE_TIME_FORMAT)
				.tz(this.partner.timezone)
				.format('hh:mm A')
		},

		/**
		 * Get the selected providers for a specific provider group.
		 *
		 * @return {Array}
		 */
		providerGroupProviders() {
			if (!this.calendar || !this.providerGroupId) {
				return []
			}

			const providerGroup = this.findProviderGroup(this.providerGroupId)

			if (!providerGroup) {
				return []
			}

			return providerGroup?.providers || []
		},

		/**
		 * Get the linked partners.
		 *
		 * @return {Array}
		 */
		linkedPartners() {
			if (!this.partner) {
				return []
			}

			return sortBy(this.partner.linked_partners, 'name')
		},

		/**
		 * Get the linked partner's calendars.
		 *
		 * @return {Array}
		 */
		linkedPartnerCalendars() {
			if (!this.linkedPartner) {
				return []
			}

			const linkedPartner = this.linkedPartners.filter(
				linkedPartner => linkedPartner.id === this.linkedPartner
			)

			const calendars = linkedPartner[0].calendars.map(calendar => {
				return {
					...calendar,
					name: calendar.is_default
						? `${calendar.name} *`
						: calendar.name,
				}
			})

			return sortBy(calendars, 'name')
		},

		/**
		 * Get the provider groups.
		 *
		 * @return {Array}
		 */
		providerGroups() {
			if (!this.calendar) {
				return []
			}

			return sortBy(this.getProviderGroups(this.partner.id), 'name')
		},

		/**
		 * Get the shareable calendars.
		 *
		 * @return {Array}
		 */
		shareableCalendars() {
			if (!this.calendar) {
				return []
			}

			const calendars = this.getCalendars(this.partner.id)

			if (!Array.isArray(calendars)) {
				return []
			}

			return calendars.filter(calendar => {
				return calendar.id !== this.calendar?.id
			})
		},

		/**
		 * Determine if the secondary provider dropdown should be shown.
		 *
		 * @return {Boolean}
		 */
		showSecondaryProvider() {
			return this.isProviderType && this.isInternalProvider
		},

		/**
		 * Get the until date's configuration.
		 *
		 * @return {Object}
		 */
		untilDateConfigs() {
			return {
				allowInput: true,
				wrap: true,
				altInput: true,
				altFormat: 'F j, Y',
				dateFormat: 'm-d-Y',
				minDate: this.startDate,
			}
		},

		...mapGetters({
			findCalendar: 'calendars/find',
			findEvent: 'events/find',
			findProviderGroup: 'partners/findProviderGroup',
			getCalendars: 'calendars/getByPartner',
			getLinkedCalendar: 'events/getLinkedCalendar',
			getPrimaryProvider: 'events/getPrimaryProvider',
			getAllProviders: 'providers/all',
			getProviderGroup: 'events/getProviderGroup',
			getSecondaryProvider: 'events/getSecondaryProvider',
			hasOfficeManagerAccess: 'auth/hasOfficeManagerAccess',
			isPrimaryCalendarEvent: 'events/isPrimaryCalendarEvent',
			isSharedEvent: 'events/isSharedEvent',
		}),

		...mapGetters('partners', {
			partner: 'active',
			getProviderGroups: 'getProviderGroups',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Create a new event exception.
		 *
		 * @return {Promise}
		 */
		createEventException() {
			return this.$api
				.eventExceptions()
				.create(this.event.id, this.activeDate.format(API_FORMAT))
		},

		/**
		 * Fill the form depending on the selected type.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (this.event.is_recurring) {
				this.wantRecurrence = true
				this.fillRecurrence()
			}

			this.eventType = this.event.type
			this.form.calendars = this.calendars
			this.form.event_type_id = this.eventType.id

			if (this.isProviderType) {
				return this.fillFormForProviderEventType()
			}

			if (this.isProviderGroupType) {
				return this.fillFormForProviderGroupEventType()
			}

			if (this.isLinkedCalendarType) {
				return this.fillFormForLinkedCalendarEventType()
			}
		},

		/**
		 * Fill the form for a provider type event.
		 *
		 * @return {void}
		 */
		fillFormForProviderEventType() {
			const primary = this.getPrimaryProvider(this.event.id)
			const secondary = this.getSecondaryProvider(this.event.id)

			this.primaryProvider = {
				id: primary.id,
				label: this.getProviderFullName(primary),
				type: primary.type,
			}

			this.secondaryProvider = null

			if (secondary) {
				this.secondaryProvider = {
					id: secondary.id,
					type: secondary.type,
					label: this.getProviderFullName(secondary),
				}
			}
		},

		/**
		 * Fill the form for a provider group event type.
		 *
		 * @return {void}
		 */
		fillFormForProviderGroupEventType() {
			const providerGroup = this.getProviderGroup(this.event.id)

			this.providerGroupId = providerGroup.id
		},

		/**
		 * Fill the form for a linked calendar event type.
		 *
		 * @return {void}
		 */
		fillFormForLinkedCalendarEventType() {
			const calendar = this.getLinkedCalendar(this.event.id)

			this.linkedCalendarId = calendar.id
			this.linkedPartner = calendar.partner_id
		},

		/**
		 * Fill the form recurrence.
		 *
		 * @return {void}
		 */
		fillRecurrence() {
			this.form.recurrence = {
				until: this.event.recurrence.until,
				frequency: this.event.recurrence.freq,
				interval: this.event.recurrence.interval,
				byweekday: [...this.event.recurrence.byweekday],
			}
		},

		/**
		 * Get the event duration.
		 *
		 * @return {Number}
		 */
		getDuration() {
			const end = moment(this.form.ends_at, DATE_TIME_FORMAT).utc()

			const duration = end.diff(this.form.starts_at)

			return moment.duration(duration).asMinutes()
		},

		/**
		 * Get the provider's full name.
		 *
		 * @param {Object} provider
		 * @return {String}
		 */
		getProviderFullName(provider) {
			if (!provider) {
				return ''
			}

			return provider.type === 'external'
				? `(Ext) ${provider.full_name}`
				: provider.full_name
		},

		/**
		 * Get the shared calendars.
		 *
		 * @return {void}
		 */
		getSharedCalendars() {
			this.calendars = this.event.calendars.reduce((accu, calendar) => {
				if (calendar.id !== parseInt(this.$route.params.id)) {
					accu.push(calendar.id)
				}

				return accu
			}, [])
		},

		/**
		 * Get the event start time.
		 *
		 * @return {moment}
		 */
		getStartTime() {
			const start = moment(this.form.starts_at, DATE_TIME_FORMAT).utc()

			return start
		},

		/**
		 * Handle the recurrence by week day change event.
		 *
		 * @param {Array} days
		 * @return {void}
		 */
		onByWeekdayChange(days) {
			this.form.recurrence.byweekday = days
		},

		/**
		 * Handle the cancel click event.
		 *
		 * @return {void}
		 */
		onCancel() {
			this.confirm = null
		},

		/**
		 * Handle the on days change event.
		 *
		 * @param {Array} days
		 * @return {void}
		 */
		onDaysChange(days) {
			this.daysSelected = days
		},

		/**
		 * Handle the on delete click event.
		 *
		 * @param {Boolean} following
		 * @return {void}
		 */
		async onDeleteClick(following = false) {
			if (this.event.is_recurring && this.isChildOccurrence) {
				this.onDeleteRecurrence(following)

				return this.resetAndCancel()
			}

			try {
				const response = await this.delete({
					eventId: this.event.id,
					calendarId: this.event.calendar_id,
				})

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}

			this.resetForm()

			this.linkedPartner = null

			this.$emit('cancel')
		},

		/**
		 * Delete the current and the following events.
		 *
		 * @return {void}
		 */
		async deleteThisAndFollowingEvent() {
			this.form.recurrence.until = this.activeDate.format(API_FORMAT)

			await this.onFormSubmit()

			await this.$store.dispatch('events/find', {
				calendar: this.event.calendar_id,
				id: this.event.id,
			})
		},

		/**
		 * Handle the date change.
		 *
		 * @return {void}
		 */
		onDateChange() {
			this.isLessThan24Hours = this.form.isLessThan24Hours()
		},

		/**
		 * Delete the event recurrence.
		 *
		 * @param {Boolean} following
		 * @return {void}
		 */
		async onDeleteRecurrence(following) {
			try {
				this.isDeleting = true

				if (following) {
					return await this.deleteThisAndFollowingEvent()
				}

				await this.createEventException({
					event: this.event.id,
					starts_at: this.activeDate.format(API_FORMAT),
				})

				await this.$store.dispatch('events/find', {
					calendar: this.event.calendar_id,
					id: this.event.id,
				})
			} catch (e) {
				return this.$alert.response(e)
			}
		},

		/**
		 * Handle the event change event.
		 *
		 * @return {void}
		 */
		onEventChange() {
			if (!this.event || isEmpty(this.event)) {
				this.linkedPartner = null

				return this.resetForm()
			}

			let start = this.event.starts_at.clone().local()
			let end = this.event.ends_at.clone().local()

			if (this.isChildOccurrence) {
				start = this.formatStartAccordingToDST(this.activeDate.clone())
				end = start.clone().add(this.event.duration, 'minutes')
			}

			this.form.starts_at = start.format(DATE_TIME_FORMAT)
			this.form.ends_at = end.format(DATE_TIME_FORMAT)

			this.getSharedCalendars()

			this.fillForm()

			this.isLessThan24Hours = this.form.isLessThan24Hours()

			this.confirm = null
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				if (!this.isLessThan24Hours) {
					this.form.recurrence = null
				}

				const response = await this.form.submit()

				this.$alert.response(response)
			} catch (e) {
				return this.$alert.response(e)
			}

			this.resetAndCancel()
		},

		/**
		 * Handle the recurrence frequency change event.
		 *
		 * @param {String} frequency
		 * @return {void}
		 */
		onFrequencyChange(frequency) {
			this.form.recurrence.frequency = frequency
		},

		/**
		 * Handle the on group coverage change event.
		 *
		 * @param {Array} coverages
		 * @return {void}
		 */
		onGroupChange(coverages) {
			this.form.set('coverages', coverages)
		},

		/**
		 * Handle the last element tab event.
		 *
		 * @param {Object} event
		 * @return {void}
		 */
		onLastElementTab(event) {
			this.$emit('last-element-tab', event)
		},

		/**
		 * Handle the recurrence until day event.
		 *
		 * @param {String} until
		 * @return {void}
		 */
		onUntilChange(until) {
			this.form.recurrence.until = moment(until, DATE_TIME_FORMAT).format(
				API_FORMAT
			)
		},

		/**
		 * Handle the want recurrence change event.
		 *
		 * @return {void}
		 */
		onWantRecurrenceChange(wantRecurrence) {
			this.wantRecurrence = wantRecurrence
		},

		/**
		 * Reset and cancel the form.
		 *
		 * @return {void}
		 */
		resetAndCancel() {
			this.resetForm()

			this.$emit('cancel')
		},

		/**
		 * Reset the form back to its original state.
		 *
		 * @return {void}
		 */
		resetForm() {
			this.isDeleting = false
			this.eventType = null

			this.linkedPartner = null
			this.primaryProvider = null
			this.providerGroupId = null
			this.secondaryProvider = null

			this.form.starts_at = moment()
				.set('hour', 9)
				.format(DATE_TIME_FORMAT)

			this.form.ends_at = moment()
				.set('hour', 9)
				.format(DATE_TIME_FORMAT)

			this.form.reset()
		},

		/**
		 * Reset the form unslected fields based on type when the form is submitted.
		 *
		 * @return {void}
		 */
		resetUnselectedFormType(data) {
			if (this.getEventTypeName === 'provider') {
				delete data.linked_calendars
				delete data.provider_groups
			}

			if (this.getEventTypeName === 'provider-group') {
				delete data.linked_calendars
				delete data.providers
			}

			if (this.getEventTypeName === 'linked-calendar') {
				delete data.provider_groups
				delete data.providers
			}

			return data
		},

		/**
		 * Set the form depending on the selected type.
		 *
		 * @return {void}
		 */
		setForm(data) {
			const start = this.getStartTime()

			data.starts_at = start.format(API_FORMAT)
			data.duration = this.getDuration(start)
			data.use_time_blocks = false
			data.use_default_times = false
			data.calendars = this.calendars

			if (this.isDeleting) {
				const duration = this.event.ends_at.diff(this.event.starts_at)

				data.duration = moment.duration(duration).asMinutes()

				data.starts_at = this.event.starts_at.format(
					'YYYY-MM-DD hh:mm:ss'
				)
			}

			switch (this.getEventTypeName) {
				case 'provider':
					data = this.setFormProviderType(data)

					break
				case 'provider-group':
					data = this.setFormProviderGroupType(data)

					break
				case 'linked-calendar':
					data = this.setFormLinkedCalendarType(data)

					break
			}

			data.event_type_id = this.eventType.id

			return data
		},

		/**
		 * Set the form's calendar id from the current calendar id.
		 *
		 * @return {VueComponent}
		 */
		setFormCalendarId(data) {
			data.calendar_id = this.calendar.id

			return data
		},

		/**
		 * Set the form's provider for the given form type.
		 *
		 * @param {Object} data
		 * @return {Object}
		 */
		setFormProviderType(data) {
			data.providers = []

			if (this.primaryProvider) {
				data.providers.push(this.primaryProvider.id)
			}

			if (this.secondaryProvider) {
				data.providers.push(this.secondaryProvider.id)
			}

			return data
		},

		/**
		 * Set the form's provider group for the given form type.
		 *
		 * @param {Object} data
		 * @return {Object}
		 */
		setFormProviderGroupType(data) {
			data.provider_groups = []

			if (this.providerGroupId) {
				data.provider_groups.push(this.providerGroupId)
			}

			return data
		},

		/**
		 * Set the form's linked calendar for the given form type.
		 *
		 * @param {Object} data
		 * @return {Object}
		 */
		setFormLinkedCalendarType(data) {
			data.linked_calendars = []

			if (this.linkedCalendarId) {
				data.linked_calendars.push(this.linkedCalendarId)
			}

			return data
		},

		/**
		 * Determine if the recurrence should be removed.
		 *
		 * @return {void}
		 */
		removeRecurrence(data) {
			if (
				this.wantRecurrence &&
				this.isLessThan24Hours &&
				!this.isChildOccurrence
			) {
				return data
			}

			data.recurrence = null

			return data
		},

		/**
		 * Send the update event request.
		 *
		 * @param {?String} url
		 * @param {?String} requestType
		 * @param {Object} data
		 * @return {void}
		 */
		async submitHandler(url, requestType, data) {
			if (this.isChildOccurrence && !this.isDeleting) {
				data = this.removeRecurrence(data)

				await this.createEventException({
					event: this.event.id,
					starts_at: this.activeDate.format(API_FORMAT),
				})

				return await this.create({
					calendarId: this.event.calendar_id,
					event: data,
				})
			}

			return await this.update({
				eventId: this.event.id,
				calendar: this.event.calendar_id,
				event: data,
			})
		},

		formatStartAccordingToDST(current) {
			const currentDate = current.clone()
			const rangeStartDate = moment
				.utc(this.event.starts_at.clone())
				.tz('America/Chicago')

			const isCurrentDST = currentDate.isDST()
			const isRangeStartDST = rangeStartDate.isDST()

			if (isRangeStartDST && !isCurrentDST) {
				return currentDate.clone().subtract(1, 'hour')
			} else if (!isRangeStartDST && isCurrentDST) {
				return currentDate.clone().add(1, 'hour')
			}
			return currentDate.clone()
		},

		...mapActions('events', [
			'create',
			'createEventException',
			'delete',
			'update',
		]),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EditEventForm',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The active date that has been clicked.
		 *
		 * @type {Object}
		 */
		activeDate: {
			required: true,
			validator: prop =>
				['object'].includes(typeof prop) || prop === null,
		},

		/**
		 * The event's id to be edited.
		 *
		 * @type {String}
		 */
		id: {
			required: true,
			validator: prop =>
				['number', 'string'].includes(typeof prop) || prop === null,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the event object for changes.
		 *
		 * @return {void}
		 */
		event: {
			handler: 'onEventChange',
			immediate: true,
		},

		/**
		 * Watch the eventType property for changes.
		 */
		eventType() {
			if (!this.isLinkedCalendarType) {
				this.linkedPartner = null
			}
		},

		/**
		 * Watch the form.starts_at property for changes.
		 */
		'form.starts_at': {
			handler: 'onDateChange',
		},

		/**
		 * Watch the form.ends_at property for changes.
		 */
		'form.ends_at': {
			handler: 'onDateChange',
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.form.setSubmitHandler(this.submitHandler).before(form => {
			if (!this.hasOfficeManagerAccess) {
				return
			}

			let data = this.setFormCalendarId(form)

			data = this.resetUnselectedFormType(data)

			data = this.removeRecurrence(data)

			return this.setForm(data)
		})
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			calendars: [],
			confirm: null,
			daysSelected: [],
			ends: false,
			endsOn: null,
			form: new EditEventForm(),
			eventType: null,
			isDeleting: false,
			isLessThan24Hours: false,
			linkedCalendarId: null,
			linkedPartner: null,
			openPicker: null,
			primaryProvider: null,
			providerGroupId: null,
			recurrence: false,
			secondaryProvider: null,
			timezones,
			today: moment().format(DATE_TIME_FORMAT),
			wantRecurrence: false,
		}
	},
}
</script>
